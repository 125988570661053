import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ConfigProvider } from "antd";
import Landing from './pages/Landing/Landing';
import Maps from './pages/Maps/Maps';
import Analytics from "./pages/Analytics/Analytics";

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#19346F',
        },
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/maps" element={<Maps />} />
          <Route path="/analytics" element={<Analytics />} />
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
