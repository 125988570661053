import { ReactNode } from "react";
import s from "./Layout.module.css";

type LayoutProps = {
  children: ReactNode
}

const Layout = ({ children }: LayoutProps) => {
  return <>
    <div className={s.wrapper}>
      <div className={s.backgroundImage}></div>
      <div className={s.backgroundOverlay}></div>

      <div className={s.container}>
        {children}
      </div>
    </div>
  </>
}

export default Layout;