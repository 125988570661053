import { Spin } from "antd";

type LoadingOverlayProps = {
  spinning: boolean;
};

const LoadingOverlay = ({ spinning }: LoadingOverlayProps) => {
  return <Spin spinning={spinning} fullscreen />;
};

export default LoadingOverlay;
