import { Segmented } from "antd";
import { ElevatorIcon, StairIcon } from "../Icons";
import { SegmentedValue } from "antd/es/segmented";
import s from "./AccessibleRouteSegmented.module.css";

type AccessibleRouteSegmentedProps = {
  value: SegmentedValue;
  onChange: (value: SegmentedValue) => void;
}

const AccessibleRouteSegmented = ({ value, onChange }: AccessibleRouteSegmentedProps) => {
  return <>
    <Segmented
      block
      value={value}
      onChange={onChange}
      options={[
        {
          value: 'stairs',
          label: (
            <div className={s.segmentOptions}>
              <StairIcon />
              <div>Use stairs</div>
            </div>
          ),
        },
        {
          value: 'elevator',
          label: (
            <div className={s.segmentOptions}>
              <ElevatorIcon />
              <div>Use elevator</div>
            </div>
          ),
        },
      ]}
    />
  </>
}

export default AccessibleRouteSegmented;